<template>
    <div class="cashout">
        <el-card>
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>提现记录明细</span>
                </div>
            </template>
            <div class="content-header">
                <el-row :gutter="5">
                    <el-col :span="24" style="text-align:left;">
                        <div class="searchblock">
                            <el-select v-model="merchantid" filterable style="margin-right:10px;width:150px" clearable
                                placeholder="请选择商户" size="small" v-if="ismerchant != 1">
                                <el-option v-for="item in merchants" :key="item" :value="item.ID"
                                    :label="item.Merchant"></el-option>
                            </el-select>
                            <el-date-picker v-model="range" type="datetimerange" unlink-panels range-separator="至"
                                start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts"
                                style="margin-right:10px;" size="small" :default-time="defaulttime"></el-date-picker>
                            <el-input v-model="keyword" placeholder="请输入账户/姓名" prefix-icon="el-icon-search"
                                class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                            <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down"
                                size="small">搜索</el-button>
                            <el-button type="success" @click="add" style="margin-left:10px;" class="hidden-sm-and-down"
                                size="small" v-if="ismerchant == 1">申请提现</el-button>
                            <el-button type="primary" size="small">
                                <download-excel class="btn btn-default" :fetch="fetchData" :fields="json_fields"
                                    :before-generate="startDownload" :before-finish="finishDownload"
                                    worksheet="My Worksheet" type="csv" name="提现数据.csv">
                                    <span style="padding: 11px 20px;margin: 11px -20px;">导出</span>
                                </download-excel>
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div style="padding:20px 20px 0 0;width:30%;display:flex;justify-content:space-between;">
                <span>提现中的总金额：{{ ongoing / 100 }}元</span><span>提现完成的总金额：{{ complete / 100 }}元</span>
            </div>
            <el-tabs type="border-card" style="margin-top:20px;" v-model="status" @tab-click="switchtab">
                <el-tab-pane v-for="item in TradeType" :key="item" :label="item.name" :name="item.status">
                    <el-table :data="tabledata" border size="small">
                        <el-table-column type="index" label="序号" align="center"></el-table-column>
                        <el-table-column label="商户名称" prop="Merchant"></el-table-column>
                        <el-table-column label="提现金额" prop="Amount" align="center" width="150px">
                            <template #default='scope'>
                                <span style="color:#FF0000">¥{{ (scope.row.Amount * 1) / 100 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="交易流水" prop="TradeNo" align="center">
                            <template #default='scope'>
                                <div v-if="scope.row.TradeNo">
                                    <span v-for="item in scope.row.TradeNo" :key="item">{{ item }}<br></span>
                                </div>
                                <div v-else>
                                    /
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="申请时间" prop="AddDTime" align="center" width="150px">
                            <template #default='scope'>
                                {{ formatDate(scope.row.AddDTime) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="提现状态" prop="Status" align="center" width="150px">
                            <template #default='scope'>
                                <span v-if="scope.row.Status == 1">提现中</span>
                                <span v-if="scope.row.Status == 2">已完成</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="提现账户" prop="Account"></el-table-column>
                        <el-table-column label="提现账户姓名" prop="AccountName"></el-table-column>
                        <el-table-column label="操作">
                            <template #default='scope'>
                                <el-button type="text" @click='Complete(scope.row, scope.row.ID)'
                                    v-if="scope.row.Status == 1 && ismerchant != 1 && userid == 8">一键提现</el-button>
                                <el-button type="text" @click='Complete2(scope.row.ID)'
                                    v-if="scope.row.Status == 1 && ismerchant != 1 && userid == 8">手动提现</el-button>
                                <el-button type="text" @click='Edit(scope.row.ID)'
                                    v-if="scope.row.Status == 1 && ismerchant != 1">修改提现</el-button>
                                <el-button type="text" @click='Check(scope.row.ID)'
                                    v-if="scope.row.Status == 2">查看提现</el-button>
                                <el-button type="text" @click='Log(scope.row.ID)'
                                    v-if="ismerchant != 1">提现日志</el-button>
                                <span v-if="ismerchant == 1 & scope.row.Status == 1">/</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                        :current-page="curpage" :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]"
                        layout="sizes,total, prev, pager, next, jumper" :total="totalcount" :hide-on-single-page="true"
                        style="text-align:center"></el-pagination>
                </el-tab-pane>
            </el-tabs>
        </el-card>
    </div>
    <el-dialog v-model="addcashout" title="提现申请" width="30%" center>
        <div class="info">
            <strong>提现说明</strong><br>
            <span>1.每过7天可提现1次,每次允许提现的金额为¥0.1-¥500000,金额精确到分</span><br>
            <span>2.暂时仅支持提现到支付宝</span><br>
            <span>3.提现申请提交后预计3个工作日内进行受理转账</span>
        </div>
        <el-form :model="cashoutform" ref="cashoutform" label-width="150px" :rules='rule'>
            <el-form-item label="可提现金额:">¥{{ cashoutform.Credit }}</el-form-item>
            <el-form-item label="提现账户:" required prop="Account">
                <el-input v-model="cashoutform.Account" size="small"></el-input>
            </el-form-item>
            <el-form-item label="提现账户姓名:" required prop="AccountName">
                <el-input v-model="cashoutform.AccountName" size="small"></el-input>
            </el-form-item>
            <el-form-item label="提现金额:" required prop="Amount">
                <el-input-number v-model="cashoutform.Amount" size="small" :controls='false'
                    :min="0"></el-input-number>元
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" size="small" @click="Confirm">确定</el-button>
            <el-button size="small" @click="CancelAdd">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="editcashout" title="修改提现" center width="30%">
        <el-form :model="cashoutform" ref="cashoutform" label-width="150px" :rules='rule'>
            <el-form-item label="商户名称:">{{ cashoutform.Merchant }}</el-form-item>
            <el-form-item label="可提现金额:">¥{{ cashoutform.Credit }}</el-form-item>
            <el-form-item label="提现金额:" required prop="Amount">
                <el-input-number v-model="cashoutform.Amount" size="small" :controls='false'
                    :min="0"></el-input-number>元
            </el-form-item>
            <el-form-item label="提现账户:" required prop="Account">
                <el-input v-model="cashoutform.Account" size="small"></el-input>
            </el-form-item>
            <el-form-item label="提现账户姓名:" required prop="AccountName">
                <el-input v-model="cashoutform.AccountName" size="small"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" size="small" @click="Update">确定</el-button>
            <el-button size="small" @click="CancelAdd">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="logdialog" title="提现日志" center width="40%">
        <el-table :data="log" border size="small">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column label="备注" prop="Remark"></el-table-column>
            <el-table-column label="操作者" prop="OpName"></el-table-column>
            <el-table-column label="操作时间" prop="AddDTime">
                <template #default='scope'>
                    {{ formatDate(scope.row.AddDTime) }}
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
    <el-dialog v-model="completecashout" title="一键提现" width="30%">
        <el-row style="line-height:30px;">
            <el-col :span="12" style="text-align:center">提现账户:</el-col>
            <el-col :span="12">{{ cashoutform.Account }}</el-col>
        </el-row>
        <el-row style="line-height:30px;">
            <el-col :span="12" style="text-align:center">账户名称:</el-col>
            <el-col :span="12">{{ cashoutform.AccountName }}</el-col>
        </el-row>
        <el-row style="line-height:30px;">
            <el-col :span="12" style="text-align:center">提现金额:</el-col>
            <el-col :span="12">{{ cashoutform.Amount }}元</el-col>
        </el-row>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="completecashout = false">取消</el-button>
                <el-button type="primary" @click="confirmclick">
                    确定
                </el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="completecashout2" title="手动提现" center width="40%">
        <el-form :model="cashoutform" ref="cashoutform" label-width="150px">
            <el-form-item label="商户名称:">{{ cashoutform.Merchant }}</el-form-item>
            <el-form-item label="提现金额:">
                ¥{{ cashoutform.Amount }}
            </el-form-item>
            <el-form-item label="提现账户:">
                {{ cashoutform.Account }}
            </el-form-item>
            <el-form-item label="提现账户姓名:">
                {{ cashoutform.AccountName }}
            </el-form-item>
            <el-form-item v-for="item, index in cashoutform.tradeno" :key="item" :label="index == 0 ? '交易流水:' : ''">
                <el-input v-model="item.value" size="small" style="width:300px"></el-input>
                <el-button v-if="index == 0" icon="el-icon-plus" size="small" type="primary" style="margin-left:10px;"
                    @click="addtradeno"></el-button>
                <el-button v-else icon="el-icon-close" size="small" type="danger" style="margin-left:10px;"
                    @click="deltradeno(index)"></el-button>
            </el-form-item>
            <el-form-item label="交易完成时间:" prop="CompleteDTime"
                :rules="{ required: true, message: '必须选择交易完成时间', trigger: 'blur' }">
                <el-date-picker type="datetime" v-model="cashoutform.CompleteDTime" placeholder="选择日期"
                    size="small"></el-date-picker>
            </el-form-item>
            <el-form-item label="交易截图:">
                <el-upload :action="upload_url" multiple :file-list="FileList" accept="image/*"
                    :on-success="UploadSuccess" :on-change="UploadChange" :on-error="UploadError"
                    :on-exceed="UploadExceed" :on-remove="RemovePic" :on-preview="Preview" list-type="picture-card"
                    :before-upload="CheckFile" :limit="10">
                    <i class="el-icon-plus"></i>
                    <template #tip>
                        <div class="el-upload__tip">只能上传 jpg/png/pdf 文件,最多上传10张</div>
                    </template>
                </el-upload>
                <el-dialog v-model="dialogVisible">
                    <img style="width:100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" size="small" @click="SaveComplete"
                :disabled='executing ? true : false'>确定</el-button>
            <el-button size="small" @click="CancelComplete">取消</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="cashoutdetail" title="查看提现详情" center width="40%">
        <el-form :model="cashoutform" ref="cashoutform" label-width="150px">
            <el-form-item label="商户名称:">{{ cashoutform.Merchant }}</el-form-item>
            <el-form-item label="提现金额:">
                ¥{{ cashoutform.Amount / 100 }}
            </el-form-item>
            <el-form-item label="提现账户:">
                {{ cashoutform.Account }}
            </el-form-item>
            <el-form-item label="提现账户姓名:">
                {{ cashoutform.AccountName }}
            </el-form-item>
            <el-form-item v-for="item, index in cashoutform.Tradeno" :key="item" :label="index == 0 ? '交易流水:' : ''">
                {{ item }}
            </el-form-item>
            <el-form-item label="交易完成时间:">
                {{ formatDate(cashoutform.CompleteDTime) }}
            </el-form-item>
            <el-form-item label="交易截图:">
                <el-image v-for="url in cashoutform.Images" :key="url" :src="url" lazy
                    style="width: 150px; height: 150px; padding: 15px;"
                    :preview-src-list="cashoutform.Images"></el-image>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button size="small" @click="Close">取消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import qs from 'qs'
import Cookies from 'js-cookie'
import { ref } from 'vue'
import JsonExcel from "vue-json-excel3";
import constant from '@/constant'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    components: {
        downloadExcel: JsonExcel,
    },
    data() {
        return {
            executing: false,
            datetimerange: [
                new Date(2022, 1, 1, 0, 0, 0),
                new Date(2022, 1, 1, 23, 59, 59)
            ],
            ongoing: 0,
            complete: 0,
            shortcuts: [
                {
                    text: '今天',
                    value: () => {
                        const start = new Date();
                        let ret_start = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0);
                        let ret_end = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 23, 59, 59);
                        console.log('s' + ret_start);
                        console.log('e' + ret_end);
                        return [ret_start, ret_end]
                    }
                }
            ],
            TradeType: [
                {
                    name: '全部',
                    status: '0',
                },
                {
                    name: '提现中',
                    status: '1',
                },
                {
                    name: '已完成',
                    status: '2',
                },
            ],
            cashoutdetail: false,
            dialogImageUrl: '',
            dialogVisible: false,
            upload_url: constant.upload_temp_img_url,
            completecashout: ref(false),
            completecashout2: false,
            logdialog: false,
            curpage: 1,
            pagesize: 10,
            totalcount: '',
            range: [],
            keyword: '',
            status: '',
            editcashout: false,
            addcashout: false,
            checkcashout: false,
            tabledata: [],
            log: [],
            cashoutform: {
                Credit: '',
                Merchant: '',
                MerchantID: this.mid,
                Account: '',
                AccountName: '',
                Amount: 0,
                ID: '',
                tradeno: [
                    {
                        value: '',
                    }
                ],
                images: [],
                CompleteDTime: '',
            },
            rule: {
                Account: [{
                    required: true,
                    message: '请输入提现账户',
                    trigger: 'blur'
                }],
                AccountName: [{
                    required: true,
                    message: '请输入提现账户姓名',
                    trigger: 'blur'
                }],
                Amount: [{
                    required: true,
                    message: '请输入提现金额',
                    trigger: 'blur'
                }]
            },
            cashoutinfo: {},
            merchants: [],
            merchantid: '',
            userid: "",
            item: "",
            json_fields: {
                "商户名称": "Merchant",
                "提现金额": "Amount",
                "交易流水": "TradeNo",
                "申请时间": "AddDTime",
                "提现账户": "Account",
                "账户姓名": "AccountName",
                "提现状态": {
                    field: "Status", callback: (value) => {
                        if (value == 1) {
                            return `未完成`;
                        }
                        if (value == 2) {
                            return `已完成`;
                        }
                    }
                },
            },
        }
    },
    methods: {
        async fetchData() {
            const response = await this.axios.get(constant.export3, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    ismerchant: this.ismerchant,
                    mid: this.mid,
                    merchantid: this.merchantid,
                    range: JSON.stringify(this.range),
                    keyword: this.keyword,
                    status: this.status,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                }
            })
            console.log(response.data.exportdata)
            if (response.data.exportdata.length == 0) {
                ElMessage({
                    type: "warning",
                    message: "暂无数据可下载",
                    center: true
                })
                return false;
            }
            return response.data.exportdata;
        },
        startDownload() {
            ElMessage({
                type: "warning",
                message: "开始下载",
                center: true,
            })
        },
        finishDownload() {
            ElMessage({
                type: "success",
                message: "下载完成",
                center: true,
            })
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        addtradeno() {
            this.cashoutform.tradeno.push({
                value: ''
            })
        },
        deltradeno(index) {
            this.cashoutform.tradeno.splice(index, 1);
        },
        Log(id) {
            console.log(id);
            this.axios.get(constant.cashoutlog, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                this.log = response.data;
                this.logdialog = true;
            });
        },
        Edit(id) {
            console.log(id);
            this.axios.get(constant.getcashout, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                this.editcashout = true;
                this.cashoutform = response.data;
            });
        },
        Check(id) {
            console.log(id);
            this.axios.get(constant.checkcashout, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                this.cashoutform = response.data;
                this.cashoutdetail = true;
            });
        },
        Close() {
            this.cashoutdetail = false;
        },
        confirmclick() {
            ElMessageBox.confirm(
                '确定提现?',
                '提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    console.log(this.cashoutform)
                    this.axios.post(constant.alipay, qs.stringify(this.cashoutform), {
                        headers: {
                            "content-type": "application/x-www-form-urlencoded"
                        }
                    }).then((response) => {
                        console.log(response)
                        if (response.data.code == 10000) {
                            ElMessage({
                                type: "success",
                                message: "提现成功"
                            })
                            this.item.Status = 2
                            this.completecashout = false
                        } else {
                            ElMessage({
                                type: "error",
                                message: response.data.sub_msg
                            })
                        }
                    })
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消提现',
                    })
                })
        },
        Complete2(id) {
            console.log(id);
            this.axios.get(constant.getcashout, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                this.completecashout2 = true;
                this.cashoutform = response.data;
                this.cashoutform.tradeno = [{
                    value: '',
                }];
                this.cashoutform.images = [];
                this.completecashout = false
            });
        },
        Complete(item, id) {
            this.item = item
            console.log(id);
            this.axios.get(constant.getcashout, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                this.completecashout = true;
                this.cashoutform = response.data;
                // this.cashoutform.tradeno = [{
                //     value:'',
                // }];
                // this.cashoutform.images = [];
            });
        },
        SaveComplete() {
            if (this.cashoutform.tradeno.length == 1) {
                if (!this.cashoutform.tradeno[0].value) {
                    this.$message.error("必须填写至少一条交易流水号");
                    return false;
                }
            }
            if (this.cashoutform.images.length <= 0) {
                this.$message.error("必须上传至少一张交易完成截图");
                return false;
            }
            this.$refs['cashoutform'].validate((valid) => {
                if (valid) {
                    console.log(this.cashoutform);
                    this.executing = true;
                    this.axios.post(constant.completecashout, qs.stringify(this.cashoutform), {
                        headers: {
                            "content-type": "application/x-www-form-urlencoded"
                        }
                    }).then((response) => {
                        console.log(response.data);
                        if (response.data == "OK") {
                            this.$message.success("操作成功");
                            this.$router.go(0);
                        } else {
                            this.$message.error("操作失败");
                        }
                        this.executing = false;
                    });
                } else {
                    return false;
                }
            });
        },
        CancelComplete() {
            this.completecashout2 = false;
        },
        Update() {
            if (this.cashoutform.Amount * 1 > this.cashoutform.Credit * 1) {
                this.$message.error("提现金额不能超过当前可提现额度");
                return false;
            }
            if (this.cashoutform.Amount * 1 < 200 || this.cashoutform.Amount * 1 > 100000) {
                this.$message.error("提现额度必须在200到100000之间");
                return false;
            }
            this.axios.post(constant.updatecashout, qs.stringify(this.cashoutform), {
                headers: {
                    "content-type": "application/x-www-form-urlencoded"
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data == 'OK') {
                    this.$message.success("操作成功");
                    this.$router.go(0);
                } else {
                    this.$message.error("操作失败");
                }
            });
        },
        switchtab() {
            console.log(this.status);
            this.axios.get(constant.cashoutlist, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    curpage: 1,
                    pagesize: 10,
                    keyword: this.keyword,
                    merchantid: this.merchantid,
                    range: JSON.stringify(this.range),
                    status: this.status,
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
            });
        },
        init() {
            this.axios.get(constant.cashoutlist, {
                headers: {
                    "content-type": "application/sjon"
                },
                params: {
                    ismerchant: this.ismerchant,
                    mid: this.mid,
                    merchantid: this.merchantid,
                    range: JSON.stringify(this.range),
                    keyword: this.keyword,
                    status: this.status,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
                this.ongoing = response.data.ongoing * 1;
                this.complete = response.data.complete * 1;
            });
        },
        add() {
            this.axios.get(constant.addcashout, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    merchantid: this.mid
                }
            }).then((response) => {
                console.log(response.data);
                let credit = (response.data * 1) / 100;
                this.cashoutform.Credit = credit;
                this.addcashout = true;
            });
            console.log(this.cashoutform);
        },
        CancelAdd() {
            this.addcashout = false;
            this.editcashout = false;
        },
        Confirm() {
            console.log("a" + this.cashoutform.Amount + "c" + this.cashoutform.Credit);
            //return false;
            if (this.cashoutform.Amount * 1 < 0.1 || this.cashoutform.Amount * 1 > 500000) {
                this.$message.error("不符合提现需求");
                return false;
            }
            if (this.cashoutform.Amount * 1 > this.cashoutform.Credit * 1) {
                this.$message.error("不能超过可提现额度");
                return false;
            }
            this.$refs['cashoutform'].validate((valid) => {
                if (valid) {
                    console.log(this.cashoutform);
                    this.axios.post(constant.savecashout, qs.stringify(this.cashoutform), {
                        headers: {
                            "content-type": "application/x-www-form-urlencoded"
                        },
                    }).then((response) => {
                        console.log(response.data);
                        if (response.data == 'OK') {
                            this.$message.success("操作成功");
                            this.addcashout = false
                        } else if (response.data == 'HAVEONGOING') {
                            this.$message.error("还有提现中的申请,请等待提现完成之后再进行操作");
                        } else if (response.data == 'NOAMOUNT') {
                            this.$message.error("提现金额不能为0");
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        formatDate(date) {
            var datetime = new Date(date * 1000);
            var year = datetime.getFullYear();
            var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
            var day = ("0" + datetime.getDate()).slice(-2);
            var hour = ("0" + datetime.getHours()).slice(-2);
            var minute = ("0" + datetime.getMinutes()).slice(-2);
            var second = ("0" + datetime.getSeconds()).slice(-2);
            return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
        UploadExceed(file, filelist) {
            this.$message.warning(`当前限制选择 10 个图片,本次选择了 ${file.length} 个文件,共选择了 ${file.length + filelist.length} 个文件`);
        },
        UploadSuccess(response, file, filelist) {
            console.log(file);
            console.log(filelist);
            console.log(response);
            if (response.error == '0') {
                this.cashoutform.images = [];
                for (var i = 0; i < filelist.length; i++) {
                    var uid = filelist[i]['uid'];
                    var name = filelist[i]['name'];
                    var url = '';
                    if (filelist[i]['response']) {
                        var upload_response = filelist[i]['response'];
                        url = upload_response.url;
                    } else {
                        url = filelist[i]['url'];
                    }
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
                    this.cashoutform.images.push(obj);
                }
            }
            console.log(this.cashoutform.images);
        },
        RemovePic(file, filelist) {
            console.log(file);
            console.log(filelist);
            this.cashoutform.images = [];
            for (var i = 0; i < filelist.length; i++) {
                var uid = filelist[i]['uid'];
                var name = filelist[i]['name'];
                var url = filelist[i]['url'];
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                this.cashoutform.images.push(obj);
            }
        },
        CheckFile(file) {
            console.log(file);
            if (file.type != "image/jpg" && file.type != "image/png" && file.type != "image/jpeg") {
                this.$message.warning('当前选择文件中包含不支持上传的类型');
                return false;
            }
        },
        UploadError(err) {
            console.log(err);
            this.$message.error(err);
        },
        Preview(file) {
            console.log(file);
            if (file.response) {
                if (file.response.error == 0) {
                    this.dialogImageUrl = file.response.url;
                    console.log(this.dialogImageUrl);
                }
            }
            else {
                this.dialogImageUrl = file.url;
            }
            this.dialogVisible = true;
        },
    },
    created: function () {
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.userid = Cookies.get("id");
        console.log(this.userid)
        if (this.mid) {
            this.cashoutform.MerchantID = this.mid;
            console.log("mid--" + this.mid);
            this.merchantid = this.mid;
        }
        this.axios.get(constant.allmerchant, {
            headers: {
                "content-type": "application/json"
            }
        }).then((response) => {
            console.log(response.data);
            this.merchants = response.data;
        });
        this.init();
    }
}
</script>
<style scoped>
.el-form-item {
    margin-bottom: 15px;
}

.info {
    padding: 5px 10px;
    background-color: #ecf5ff;
    border-color: #d9ecff;
    color: #409eff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.info * {
    padding: 2px;
}
</style>